import { Link, Maybe } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { NextRouter } from 'next/router'
import { ParsedUrlQuery, ParsedUrlQueryInput, parse } from 'querystring'
import { getHash } from '@dtx-company/true-common/src/utils/strings'
import { useMediaQuery } from '@dtx-company/true-common/src/hooks/useMediaQuery'
import COUNTRY_CODES from '../constants/countryCodes'
import isMobilePhone, { MobilePhoneLocale, locales } from 'validator/lib/isMobilePhone'
import type { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'

const logos = JSON.parse(process.env.RESERVED_LOGOS ?? '{}')

declare module 'validator/lib/isMobilePhone' {
  // `locales` is missing from @types/validator
  const locales: MobilePhoneLocale[]
}

export interface CategoryObj {
  links: LinkType[]
  contact: LinkType[]
}

function logoUrlForFeatureLink(featureType: string): string {
  switch (featureType) {
    case 'aapi':
      return '/images/aapi_feature.png'
    case 'blm':
    default:
      return '/images/feature.png'
  }
}

export function logoUrlForType(
  linkProvider: Link['provider'],
  featureType?: string | null
): string {
  if (linkProvider === LinkProvider.FEATURE && featureType) {
    return logoUrlForFeatureLink(featureType)
  } else if (linkProvider === 'twitter') {
    return '/icons/social-media/twitter.svg'
  }
  return logos?.[linkProvider + '-color'] ?? '/icons/social-media/blank.png'
}

export function logoUrlForTypeDisabled(
  linkProvider: string | null,
  featureType?: string | null
): string {
  if (!linkProvider) return ''
  if (linkProvider === LinkProvider.FEATURE && featureType) {
    return logoUrlForFeatureLink(featureType)
  }
  return logos?.[linkProvider + '-disabled'] ?? '/icons/social-media/blank.png'
}

export function parseLinks(links: LinkType[]): CategoryObj {
  return { links, contact: [] }
}

export function parseLinksForCRM(links: Maybe<Maybe<LinkType>[]> | undefined): (LinkType | null)[] {
  if (!links) return []
  return links.filter(link => link?.type === 'contactCollection')
}

/**
 * sorts link via position if present with a fallback to sort via legacy order field
 */
export function sortLinks(links: LinkType[]): LinkType[] {
  links.sort((a, b) => {
    if (!a.position || !b.position) return a.order - b.order
    return a.position > b?.position ? 1 : -1
  })
  return links
}

export const getPixelParams = (params: ParsedUrlQuery): ParsedUrlQueryInput =>
  Object.entries(params).reduce((acc, [key, val]) => {
    if (key.startsWith('fce_id')) {
      return {
        ...acc,
        [key]: val
      }
    }
    return acc
  }, {})

export const getUtmParams = (params: ParsedUrlQuery): ParsedUrlQueryInput =>
  Object.entries(params).reduce((acc, [key, val]) => {
    if (key.startsWith('utm')) {
      return {
        ...acc,
        [key]: val
      }
    }
    return acc
  }, {})

export function splitCountryCode(fullNumber: string | null | undefined): string[] {
  if (!fullNumber || fullNumber?.length === 0) {
    return ['', '']
  } else if (fullNumber[0] !== '+') {
    return ['', fullNumber]
  }
  return fullNumber.replace('+', '').split('-', 2)
}

export function getLocaleForValidator(countryCode: string): MobilePhoneLocale | 'any' {
  const country = COUNTRY_CODES.find(obj => obj.phone === countryCode.replace('+', ''))
  if (country) {
    const locale = locales.find(value => {
      return value.split('-')[1] === country.code
    })

    if (locale) {
      return locale
    }
  }
  return 'any'
}

export function validatePhoneNumber(number: string, countryCode: string): boolean {
  const locale = getLocaleForValidator(countryCode)
  return number ? isMobilePhone(number, locale) : true
}

export function getQueryParamsFromString(path: string): ParsedUrlQueryInput {
  let query
  const hashInd = path.indexOf('#')
  const queryInd = path.indexOf('?')
  if (!queryInd) return {}

  if (hashInd) {
    if (hashInd < queryInd) {
      query = path.split('#')[1]?.split('?')[1] //url.com/path#hash?query
    } else if (hashInd > queryInd) {
      query = path.split('?')[1]?.split('#')[0] //url.com/path?query#hash
    }
  } else {
    query = path.split('?')[1]
  }

  return parse(query || '')
}
export async function changeProfileHashRoute(newHash: string, router: NextRouter): Promise<void> {
  const oldHash = getHash(router.asPath)
  const query = getQueryParamsFromString(router.asPath)
  if (newHash == null || newHash === oldHash) {
    return
  }
  await router.push(
    {
      query: { ...query, ...router.query }
    },
    {
      query: getUtmParams({ ...query, ...router.query } as ParsedUrlQuery),
      hash: newHash
    },
    {
      shallow: true
    }
  )
}

export function isIosDevice(): boolean {
  if (typeof navigator === 'undefined' || navigator === null) {
    return false
  }
  return /iP(hone|od|ad)/.test(navigator.userAgent)
}

export function isAndroidDevice(): boolean {
  if (typeof navigator === 'undefined' || navigator === null) {
    return false
  }
  return /Android|BlackBerry|Kindle|Silk-Accelerated|Opera M(obi|ini)|IEMobile/i.test(
    navigator.userAgent
  )
}

export function useIsMobile(): boolean {
  const mobile = useMediaQuery('(max-width:1023px)')
  return mobile
}

//from https://github.com/f2etw/detect-inapp
export function isInApp(): boolean {
  if (typeof navigator === 'undefined' || navigator === null) {
    return false
  }
  const useragent = navigator?.userAgent
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)']
  const regex = new RegExp(`(${rules.join('|')})`, 'ig')
  return Boolean(useragent.match(regex))
}
